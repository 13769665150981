import React from 'react';
import { useAuthorization } from '../../authorization/AuthorizationContext';
import { AuthorizedRouter } from './AuthorizedRouter';
import { UnauthorizedRouter } from './UnauthorizedRouter';
import { WithdrawRouter } from './WithdrawRouter';

import { ErrorBoundary } from '../ErrorBoundary';
import { Header } from '../organisms/Header';
import { Footer } from '../organisms/Footer';
import { WithdrawHeader } from '../organisms/WithdrawHeader';

import bannerWithdraw from '../../styles/banner-withdraw.jpg';
import withdrawBanner from '../../styles/withdraw-banner.jpg';

export function Router() {
  const authZoneEnabled = false;
  const { isAuthorized } = useAuthorization();

  const getScreen = () => {
    if (authZoneEnabled) {
      return (
        <div className="w-site m-auto min-h-screen flex flex-col pt-6">
          <div className="flex-shrink-0">
            {/*{isAuthorized ? (*/}
            {/*  <Header banner={bannerStops} link="adjustments" />*/}
            {/*) : (*/}
            {/*  <Header banner={bannerStopsNa} link="/" />*/}
            {/*)}*/}
            <Header banner={bannerWithdraw} link="faq" />
          </div>
          <div className="flex-grow">
            <ErrorBoundary>
              {isAuthorized ? <AuthorizedRouter /> : <UnauthorizedRouter />}
            </ErrorBoundary>
          </div>
          <div className="flex-shrink-0">
            <Footer />
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-site m-auto min-h-screen flex flex-col pt-6">
          <div className="flex-shrink-0">
            <WithdrawHeader banner={withdrawBanner} link="withdraw/form" />
          </div>
          <div className="flex-grow">
            <WithdrawRouter />
          </div>
          <div className="flex-shrink-0">
            <Footer />
          </div>
        </div>
      );
    }
  };

  return getScreen();
}
