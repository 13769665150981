import React, { useEffect } from 'react';
import '../../styles/modal-window.css';

interface ModalWindowModel {}

export const ModalWindow: React.FC<ModalWindowModel> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    let timer = setInterval(() => {}, 2000);
    return () => {
      clearInterval(timer);
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <div className={`modal-window__wrapper modal-window__wrapper_visible`}>
        <div className="modal-window__container">
          <div className="modal-window__main-info">
            <h1 className="modal-window__title">Формирование заявки...</h1>
          </div>
        </div>
      </div>
    </>
  );
};
