const {
  API_TOKEN_REFRESH_INTERVAL_MINUTES,
  CONFIRMATION_ID_LIFETIME_MINUTES,
  RECAPTCHA,
} = window;
const {
  REACT_APP_API_TOKEN_REFRESH_INTERVAL_MINUTES,
  REACT_APP_CONFIRMATION_ID_LIFETIME_MINUTES,
  REACT_APP_RECAPTCHA,
} = process.env;

const msInMinute = 60 * 1000;

export const API_TOKEN_REFRESH_INTERVAL =
  parseFloat(
    API_TOKEN_REFRESH_INTERVAL_MINUTES ||
      REACT_APP_API_TOKEN_REFRESH_INTERVAL_MINUTES ||
      '1'
  ) * msInMinute;

export const CONFIRMATION_ID_LIFETIME =
  parseFloat(
    CONFIRMATION_ID_LIFETIME_MINUTES ||
      REACT_APP_CONFIRMATION_ID_LIFETIME_MINUTES ||
      '1'
  ) * msInMinute;

export const RECAPTCHA_KEY = RECAPTCHA || REACT_APP_RECAPTCHA;

export const RECORDS_PER_PAGE = 20;
