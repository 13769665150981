import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryCache,
  QueryFunction,
  MutationConfig,
  MutateFunction,
  InfiniteQueryConfig,
} from 'react-query';
import jwtDecode from 'jwt-decode';
import {
  basicAuthorizedRequest,
  basicAuthorizedInfiniteRequest,
  basicAuthorizedMutateRequest,
  basicPaymentMutateRequest,
  loginRequest,
  logoutRequest,
  sendPaymentCodeRequest,
  refreshRequestJwt,
  confirmPaymentCodeRequest,
} from '../api/server';
import {
  API_TOKEN_REFRESH_INTERVAL,
  CONFIRMATION_ID_LIFETIME,
} from '../config/constants';
import { UserInfo, LoginRequest } from '../model/server';

export interface Authorization {
  isLoading: boolean;
  isAuthorized: boolean;
  hasPaymentToken: boolean;

  phone?: string;
  jwt?: string;
  paymentJwt?: string;

  login: MutateFunction<UserInfo, unknown, LoginRequest>;
  logout: MutateFunction<{}, unknown, undefined>;

  // sendPaymentCode: MutateFunction<{ value: string }, unknown, string>;
  sendPaymentCode: any;
  paymentConfirmationId: string | undefined;
  resetPaymentConfirmationId: any;
  confirmPaymentCode: any;
}

const AuthorizationContext = createContext<Authorization | null>(null);

export function useAuthorization() {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      `useAuthorization must be used within a AuthorizationProvider`
    );
  }
  return context;
}

const parseJwt = (jwt: string) =>
  jwtDecode(jwt) as { jti: string; exp: number };

const notExpired = (jwt: string) => {
  const decoded = parseJwt(jwt);
  const now = +new Date() / 1000;
  return decoded.exp - now > 5;
};

export async function wrapAuth<T>(
  jwt: string | undefined,
  queryFn: QueryFunction<T>
) {
  if (jwt && notExpired(jwt)) {
    return await queryFn(jwt);
  }
  const newJwt = await refreshRequestJwt();
  return await queryFn(newJwt);
}

//undefined here only to match reach-router interface with possible undefined route parameters
export function useAuthorizedQuery<T>(
  queryKey: Array<string | undefined>,
  enabled = true
  // queryConfig?: QueryConfig<T, unknown>
) {
  const { jwt } = useAuthorization();
  return useQuery<T>(
    queryKey,
    (...args) =>
      wrapAuth<T>(jwt, (jwt) => basicAuthorizedRequest(jwt, ...args)),
    {
      enabled: jwt && enabled,
      useErrorBoundary: true,
    }
  );
}

export function useAuthorizedInfiniteQuery<T>(
  queryKey: Array<string | undefined>,
  queryConfig?: InfiniteQueryConfig<T, unknown>
) {
  const { jwt } = useAuthorization();
  return useInfiniteQuery<T>(
    queryKey,
    (...args) => {
      const offset = args.pop();
      return wrapAuth<T>(jwt, (jwt) =>
        basicAuthorizedInfiniteRequest(jwt, offset, ...args)
      );
    },

    {
      enabled: jwt,
      useErrorBoundary: true,
      ...queryConfig,
    }
  );
}

export function useAuthorizedMutation<T>(
  path: string,
  method?: string,
  options?: MutationConfig<T>
) {
  const { jwt } = useAuthorization();
  return useMutation(
    (payload) =>
      wrapAuth(jwt, (jwt) =>
        basicAuthorizedMutateRequest(jwt, path, payload, method)
      ),
    options
  );
}

export function usePaymentMutation<T>(
  path: string,
  method?: string,
  options?: MutationConfig<T>
) {
  const { jwt, paymentJwt } = useAuthorization();
  return useMutation(
    (payload) =>
      basicPaymentMutateRequest(jwt, paymentJwt, path, payload, method),
    options
  );
}

export function AuthorizationProvider(props: object) {
  const AUTH_KEY = 'auth';
  const [refreshJwt, setRefreshJwt] = useState(true);
  const auth = useQuery(AUTH_KEY, refreshRequestJwt, {
    refetchInterval: refreshJwt ? API_TOKEN_REFRESH_INTERVAL : false,
    refetchIntervalInBackground: true,
  });

  let isAuthorized: boolean;
  let jwt: string | undefined;
  let phone: string | undefined;

  if (auth.status === 'success' && auth.data) {
    isAuthorized = true;
    jwt = auth.data;
    phone = parseJwt(jwt).jti;
  } else {
    isAuthorized = false;
  }

  useEffect(() => {
    setRefreshJwt(isAuthorized);
  }, [isAuthorized]);

  const queryCache = useQueryCache();
  const [login] = useMutation(loginRequest, {
    onSuccess: () => {
      queryCache.invalidateQueries(AUTH_KEY);
    },
  });
  const [logout] = useMutation(() => logoutRequest(jwt), {
    onSuccess: () => {
      queryCache.invalidateQueries(AUTH_KEY);
    },
  });

  const [paymentConfirmationId, setPaymentConfirmationId] = useState<
    string | undefined
  >();
  const [sendPaymentCode] = useMutation(
    (recaptchaToken) => sendPaymentCodeRequest(jwt, recaptchaToken),
    {
      onSuccess: (data) => {
        if (data.value) {
          setPaymentConfirmationId(data.value);
          setTimeout(() => {
            setPaymentConfirmationId(undefined);
          }, CONFIRMATION_ID_LIFETIME);
        }
      },
    }
  );

  const [paymentJwt, setPaymentJwt] = useState<string | undefined>();
  const [confirmPaymentCode] = useMutation(
    (params) => confirmPaymentCodeRequest(jwt, paymentConfirmationId, params),
    {
      onSuccess: (data) => {
        if (data) {
          setPaymentJwt(data);
          const expiration = parseJwt(data).exp;
          const now = +new Date() / 1000;
          const expireIn = expiration - now - 5;
          setTimeout(() => {
            setPaymentJwt(undefined);
          }, expireIn * 1000);
        }
      },
    }
  );
  return (
    <AuthorizationContext.Provider
      value={{
        isLoading: auth.isLoading,
        isAuthorized,
        hasPaymentToken: !!paymentJwt,

        phone,
        jwt,
        paymentJwt,
        login,
        logout,
        sendPaymentCode,
        paymentConfirmationId,
        resetPaymentConfirmationId: () => setPaymentConfirmationId(undefined),
        confirmPaymentCode,
      }}
      {...props}
    />
  );
}
