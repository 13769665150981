import {
  LoginRequest,
  UserInfo,
  CreateUserData,
  SendCodeRequest,
  ConfirmationId,
  ChangePasswordRequest,
} from '../model/server';

import { RECORDS_PER_PAGE } from '../config/constants';

function req(path: string, options: RequestInit) {
  return fetch(path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  });
}

async function checkResponse(response: Response) {
  if (response.ok) {
    return await response.json();
  }
  throw await response.json();
}

async function basicMutateRequest(
  path: string,
  payload: any,
  headers: any = {}
) {
  const response = await req(`/api/v1/${path}`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers,
  });
  return await checkResponse(response);
}

export async function loginRequest(payload: LoginRequest): Promise<UserInfo> {
  return await basicMutateRequest('auth/login', payload);
}

export async function logoutRequest(jwt: string | undefined): Promise<{}> {
  if (jwt) {
    return await basicMutateRequest(
      'users/logout',
      {},
      {
        Authorization: `Bearer ${jwt}`,
      }
    );
  }
  return {};
}

export async function sendPaymentCodeRequest(
  jwt: string | undefined,
  recaptchaToken: string | undefined
): Promise<{ value: string }> {
  if (jwt && recaptchaToken) {
    return await basicMutateRequest(
      'users/send-payment-code',
      { recaptchaToken },
      {
        Authorization: `Bearer ${jwt}`,
      }
    );
  }
  return { value: '' };
}

export async function confirmPaymentCodeRequest(
  jwt: string | undefined,
  confirmationId: string | undefined,
  params:
    | {
        confirmationCode: string;
        recaptchaToken: string;
      }
    | undefined
) {
  const response = await req('/api/v1/users/enrich-session', {
    method: 'POST',
    body: JSON.stringify({
      confirmationId,
      ...params,
    }),
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });

  const paymentJwt = response.headers.get('Rapida-Online-Payment');
  if (!response.ok) {
    throw response.status;
  }
  return paymentJwt;
}

export async function registerRequest(
  payload: CreateUserData
): Promise<UserInfo> {
  return await basicMutateRequest('auth/register', payload);
}

export async function sendRegisterCodeRequest(
  payload: SendCodeRequest
): Promise<ConfirmationId> {
  return await basicMutateRequest('auth/send-registration-code', payload);
}

export async function recoveryRequest(
  payload: ChangePasswordRequest
): Promise<UserInfo> {
  return await basicMutateRequest('auth/change-password', payload);
}

export async function sendRecoveryCodeRequest(
  payload: SendCodeRequest
): Promise<ConfirmationId> {
  return await basicMutateRequest('auth/send-recovery-code', payload);
}

export async function refreshRequestJwt() {
  const response = await req('/api/v1/auth/refresh', {
    method: 'PUT',
    body: '{}',
  });

  const jwt = response.headers.get('RAPIDA-ONLINE-API');
  return jwt;
}

export async function basicAuthorizedRequest(
  jwt: string,
  ...args: Array<string>
) {
  const response = await req(`/api/v1/${args.join('/')}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return await checkResponse(response);
}

export async function basicAuthorizedInfiniteRequest(
  jwt: string,
  offset: number = 0,
  ...args: Array<string>
) {
  const response = await req(
    `/api/v1/${args.join('/')}?offset=${offset}&limit=${RECORDS_PER_PAGE}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return await checkResponse(response);
}

export async function basicAuthorizedMutateRequest(
  jwt: string,
  path: string,
  payload: any,
  method = 'POST'
) {
  const response = await req(`/api/v1/${path}`, {
    method,
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
  return await checkResponse(response);
}

export async function basicPaymentMutateRequest(
  jwt: string | undefined,
  paymentJwt: string | undefined,
  path: string,
  payload: any,
  method = 'POST'
) {
  const response = await req(`/api/v1/${path}`, {
    method,
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Rapida-Online-Payment': `${paymentJwt}`,
    },
  });
  return await checkResponse(response);
}
