import React from 'react';
import { MainProps } from './Main';

export const WithdrawFaq: React.FC<MainProps> = () => {
  return (
    <div className="w-site m-auto border-solid border border-gray-ef rounded-b p-6 mt-6">
      <div className="mt-6">
        <div>
          <h2 className="text-2xl">Дорогой партнер!</h2>
        </div>
        <p>
          <br />
          Хотим сказать вам спасибо за то, что всё это время вы оставались с
          нами. Мы вместе следили за изменениями в мире и стали больше, чем
          рядовые партнёры, поэтому нам искренне жаль расставаться с вами.
          Google AdSense испытывает серьезные трудности с переводом средств в
          Россию, поэтому мы вынуждены закрыть сервис Online Rapida (
          <a
            className={'text-blue'}
            rel={'noreferrer'}
            target={'_blank'}
            href={
              'https://static.qiwi.com/business/agents/rapida/agreement_rapida-online.pdf'
            }
          >
            пользовательское соглашение
          </a>
          ).
          <br />
          <br />
          Если у вас остались деньги на счёте Online Rapida – пожалуйста,
          заполните заявку. Вывести деньги самостоятельно через личный кабинет
          не получится.
          <br />
          <br />
          Ещё раз спасибо и надеемся на встречу вновь,
          <br />
          ваша команда Online Rapida
        </p>
      </div>
    </div>
  );
};
