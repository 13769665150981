import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';

export interface WithdrawAnnounceProps extends RouteComponentProps {}

export const WithdrawAnnounce: React.FC<WithdrawAnnounceProps> = () => {
  return (
    <>
      <div className="flex flex-col justify-center bg-gray-ef-fake rounded-sm mt-2 p-8">
        <div>
          <h2 className="text-2xl">Дорогой партнер!</h2>
        </div>
        <p>
          <br />
          Хотим сказать вам спасибо за то, что всё это время вы оставались с
          нами. Мы вместе следили за изменениями в мире и стали больше, чем
          рядовые партнёры, поэтому нам искренне жаль расставаться с вами.
          Google AdSense испытывает серьезные трудности с переводом средств в
          Россию, поэтому мы вынуждены закрыть сервис Online Rapida (
          <a
            className={'text-blue'}
            rel={'noreferrer'}
            target={'_blank'}
            href={
              'https://static.qiwi.com/business/agents/rapida/agreement_rapida-online.pdf'
            }
          >
            пользовательское соглашение
          </a>
          ).
          <br />
          <br />
          Если у вас остались деньги на счёте Online Rapida – пожалуйста,
          заполните заявку. Вывести деньги самостоятельно через личный кабинет
          не получится.
          <br />
          <br />
          Ещё раз спасибо и надеемся на встречу вновь,
          <br />
          ваша команда Online Rapida
          <br />
          <br />
        </p>
        <Link
          to={'/withdraw/form'}
          className="bg-brand h-12 mt-4 flex justify-center items-center rounded-sm text-white 2xl:hover:bg-blue transition-all opacity-0>1"
        >
          Заполнить заявку
        </Link>
      </div>
    </>
  );
};
