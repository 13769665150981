import React, { useState } from 'react';

interface SingleLineInputModel {
  title: string;
  description?: string;
  name: string;
  id: string;
  inputType: string;
  type: string;
  required: boolean;
  minLength: number;
  maxLength: number;
  setFn: (_: string) => void;
  setIsValidFn: (_: boolean) => void;
  value: string;
}

export const SingleLineInput: React.FC<SingleLineInputModel> = ({
  title,
  description,
  name,
  id,
  type,
  inputType,
  required,
  minLength,
  maxLength,
  setFn,
  setIsValidFn,
  value,
}) => {
  const [isValidationError, setIsValidationError] = useState(false);

  const checkField = (event: HTMLInputElement) => {
    const value = event.value;
    const isValueInRange =
      minLength <= value.length && value.length <= maxLength;

    let validationError = false;

    if (value.length <= 0 && !required) {
      setIsValidationError(false);
      setIsValidFn(true);
      setFn('');
      return;
    }

    if (type === 'number') {
      const num = Number(value);
      validationError = isNaN(num) || !isValueInRange;
    }

    if (type === 'text') {
      const text = String(value);
      const hasNum = /\d/;
      validationError = hasNum.test(text) || !isValueInRange;
    }

    if (type === 'email') {
      validationError = !event.checkValidity();
    }

    setIsValidationError(validationError);
    setIsValidFn(!validationError);
    setFn('' + value);
  };

  return (
    <>
      <div className="withdrawal-funds__single-wrapper">
        <div className="withdrawal-funds__single">
          <div className="withdrawal-funds__input-container">
            <input
              id={id}
              placeholder={title}
              type={inputType}
              minLength={minLength}
              maxLength={maxLength}
              name={name}
              required={required}
              onFocus={(event) => checkField(event.target)}
              onBlur={(event) => checkField(event.target)}
              onChange={(event) => checkField(event.target)}
              className={`withdrawal-funds__input withdrawal-funds__input_default ${
                isValidationError && 'withdrawal-funds__input_error'
              }`}
              value={value}
            />
            {description && (
              <p className="withdrawal-funds__label">{description}</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
