import React from 'react';
import { Router } from '@reach/router';
import { WithdrawForm } from '../pages/WithdrawForm';
import { WithdrawAnnounce } from '../pages/WithdrawAnnounce';
import { WithdrawFaq } from '../pages/WithdrawFaq';
import { WithdrawDone } from '../pages/WithdrawDone';
import { WithdrawError } from '../pages/WithdrawError';

export function WithdrawRouter() {
  return (
    <Router>
      <WithdrawFaq path="withdraw/faq" />
      <WithdrawAnnounce path="/" />
      <WithdrawForm path="withdraw/form" />
      <WithdrawDone path="withdraw/done" />
      <WithdrawError path="withdraw/error" />
    </Router>
  );
}
