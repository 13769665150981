import React from 'react';
import { Router } from './components/routers/Router';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReactQueryDevtools } from 'react-query-devtools';
import { AuthorizationProvider } from './authorization/AuthorizationContext';
import { RECAPTCHA_KEY } from './config/constants';

import './styles/generated/tailwind.css';

const queryCache = new QueryCache();
export default function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY || ''}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <AuthorizationProvider>
          <Router />
        </AuthorizationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryCacheProvider>
    </GoogleReCaptchaProvider>
  );
}
