import React from 'react';

export interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <div className="flex flex-nowrap py-2 text-xs leading-5">
      <div className="w-3/6 flex-none">
        <div className=" text-left">
          LALIRA DMCC, лицензия DMCC-741107, регистрационный номер
          NBSPDMCC179018
          <br />
          Platinum Tower, office 2503, JLT, Dubai, United Arab Emirates, P.O.
          Box 338560
        </div>
      </div>
      <div className="w-1/6 flex-none">
        <div className="text-center"></div>
      </div>
      <div className="w-2/6 flex-none ">
        <div className="text-right">
          Служба поддержки:
          <br />
          <a
            href="mailto:onlinerapida@qiwi.com"
            className="text-blue underline"
          >
            onlinerapida@qiwi.com
          </a>
          <br />
          <a href="tel:+88002002711">8-800-200-27-11</a>
        </div>
      </div>
    </div>
  );
};
