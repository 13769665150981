import React from 'react';
import { RouteComponentProps } from '@reach/router';

export interface WithdrawErrorProps extends RouteComponentProps {}

export const WithdrawError: React.FC<WithdrawErrorProps> = () => {
  return (
    <>
      <div className="flex flex-col justify-center bg-gray-ef-fake rounded-sm mt-2 p-8">
        <div>
          <h2 className="text-2xl">Ошибка</h2>
        </div>
        <p>
          <br />
          Ошибка формирования заявки. Пожалуйста, попробуйте позже или свяжитесь
          с нами.
        </p>
      </div>
    </>
  );
};
