import React, { useState } from 'react';
import '../../styles/withdrawal-form.css';
import { SingleLineInput } from '../atoms/SingleLineInput';
import { UploadButton } from '../atoms/UploadButton';
import { MainProps } from './Main';
import { ModalWindow } from '../atoms/ModalWindow';
import { useNavigate } from '@reach/router';

export const WithdrawForm: React.FC<MainProps> = () => {
  const navigate = useNavigate();

  const [withdrawType, setWithdrawType] = useState('bank-card');

  const [isAgree, setIsAgree] = useState(false);

  const [lastName, setLastName] = useState('');
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [middleName, setMiddleName] = useState('');
  const [middleNameIsValid, setMiddleNameIsValid] = useState(false);

  const [googleId, setGoogleId] = useState('');
  const [googleIdIsValid, setGoogleIdIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  // in fact is not required, that is empty initial values are Ok
  const [passportSerial, setPassportSerial] = useState('');
  const [passportSerialIsValid, setPassportSerialIsValid] = useState(true);
  const [passportNumber, setPassportNumber] = useState('');
  const [passportNumberIsValid, setPassportNumberIsValid] = useState(true);
  const [passportIssueDate, setPassportIssueDate] = useState('');
  const [passportIssueDateIsValid, setPassportIssueDateIsValid] =
    useState(true);

  const [withdrawData, setWithdrawData] = useState('');
  const [withdrawDataIsValid, setWithdrawDataIsValid] = useState(false);
  const [withdrawAdditionalData, setWithdrawAdditionalData] = useState('');
  const [withdrawAdditionalDataIsValid, setWithdrawAdditionalDataIsValid] =
    useState(true);

  const [file, setFile] = useState<File | null>(null);
  const [fileIsValid, setFileIsValid] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const fieldsAreValid = () => {
    return (
      lastNameIsValid &&
      firstNameIsValid &&
      middleNameIsValid &&
      googleIdIsValid &&
      phoneNumberIsValid &&
      emailIsValid &&
      passportSerialIsValid &&
      passportNumberIsValid &&
      passportIssueDateIsValid &&
      withdrawDataIsValid &&
      withdrawAdditionalDataIsValid &&
      fileIsValid
    );
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.set('lastName', lastName);
    formData.set('firstName', firstName);
    formData.set('middleName', middleName);
    formData.set('googleId', googleId);
    formData.set('phoneNumber', phoneNumber);
    formData.set('email', email);
    if (passportSerial) formData.set('passportSerial', passportSerial);
    if (passportNumber) formData.set('passportNumber', passportNumber);
    if (passportIssueDate) formData.set('passportIssueDate', passportIssueDate);
    formData.set('withdrawType', withdrawType);
    formData.set('withdrawData', withdrawData);
    if (withdrawAdditionalData)
      formData.set('withdrawAdditionalData', withdrawAdditionalData);
    if (file) formData.set('attachment', file);

    const result = await fetch('/api/v1/withdraw/require', {
      method: 'POST',
      body: formData,
    });
    setIsLoading(false);

    if (result.status === 200) await navigate('../../withdraw/done');
    else await navigate('../../withdraw/error');
  };

  const selectWithdrawType = () => {
    const withdrawTypeFragment: WithdrawTypeFragment = {
      fields: [],
      attention: '',
    };

    switch (withdrawType) {
      case 'mobile-phone':
        withdrawTypeFragment.fields.push({
          title: 'Номер мобильного телефона',
          description: 'Единовременный платеж - не более 15 000 рублей',
          id: 'withdraw-data',
          name: 'withdrawData',
          minLength: 10,
          maxLength: 14,
        });

        withdrawTypeFragment.attention =
          'Единовременный платеж 15000р. Если неиспользованный остаток превышает' +
          ' это значение, то он будет разбит АО «КИВИ Банк» на несколько платежей';
        break;
      case 'bank-card':
        withdrawTypeFragment.fields.push({
          title: 'Номер вашей банковской карты',
          id: 'withdraw-data',
          name: 'withdrawData',
          minLength: 16,
          maxLength: 19,
        });

        withdrawTypeFragment.attention =
          'Единовременный платеж может быть ограничен.' +
          ' При необходимости остаток будет разбит на несколько платежей';
        break;
      case 'bank-account':
        withdrawTypeFragment.fields.push({
          title: 'Номер счёта получателя',
          description: 'Максимальная сумма для перевода - 600 000 руб.',
          id: 'withdraw-data',
          name: 'withdrawData',
          minLength: 20,
          maxLength: 20,
        });
        withdrawTypeFragment.fields.push({
          title: 'БИК банка получателя',
          description: 'Идентификатор банка',
          id: 'withdraw-additional-data',
          name: 'withdrawAdditionalData',
          minLength: 9,
          maxLength: 9,
        });

        withdrawTypeFragment.attention =
          'Единовременный платеж 600000р. Если неиспользованный остаток превышает' +
          ' это значение, то он будет разбит АО «КИВИ Банк» на несколько платежей.';
        break;
      case 'qiwi-wallet':
        withdrawTypeFragment.fields.push({
          title: 'Номер QIWI Кошелька',
          id: 'withdraw-data',
          name: 'withdrawData',
          minLength: 10,
          maxLength: 14,
        });

        withdrawTypeFragment.attention =
          'Единовременный платеж может быть ограничен.' +
          ' При необходимости остаток будет разбит на несколько платежей';
        break;
    }

    return (
      <>
        {withdrawTypeFragment.fields.map((item, index) => {
          let validationFn;
          let setFn;
          let fieldValue;
          if (item.id === 'withdraw-data') {
            setFn = setWithdrawData;
            validationFn = setWithdrawDataIsValid;
            fieldValue = withdrawData;
          } else {
            setFn = setWithdrawAdditionalData;
            validationFn = setWithdrawAdditionalDataIsValid;
            fieldValue = withdrawAdditionalData;
          }
          return (
            <SingleLineInput
              title={item.title}
              description={item.description}
              name={item.name}
              id={item.id}
              required={true}
              type={'number'}
              inputType={'text'}
              minLength={item.minLength}
              maxLength={item.maxLength}
              setFn={setFn}
              setIsValidFn={validationFn}
              key={index}
              value={fieldValue}
            />
          );
        })}
        <p className="withdrawal-funds__attention">
          {withdrawTypeFragment.attention}
        </p>
      </>
    );
  };

  return (
    <>
      {isLoading && <ModalWindow />}

      <div className="withdrawal-funds__container">
        <form className="withdrawal-funds__form">
          <h2 className="withdrawal-funds__title">
            Заявка на вывод средств из Online Rapida
          </h2>
          <SingleLineInput
            title={'Фамилия'}
            name={'lastName'}
            id={'last-name'}
            inputType={'text'}
            type={'text'}
            minLength={2}
            maxLength={16}
            required={true}
            setFn={setLastName}
            setIsValidFn={setLastNameIsValid}
            value={lastName}
          />
          <SingleLineInput
            title={'Имя'}
            name={'firstName'}
            id={'first-name'}
            inputType={'text'}
            type={'text'}
            minLength={2}
            maxLength={16}
            required={true}
            setFn={setFirstName}
            setIsValidFn={setFirstNameIsValid}
            value={firstName}
          />
          <SingleLineInput
            title={'Отчество'}
            name={'middleName'}
            id={'middle-name'}
            inputType={'text'}
            type={'text'}
            minLength={2}
            maxLength={16}
            required={true}
            setFn={setMiddleName}
            setIsValidFn={setMiddleNameIsValid}
            value={middleName}
          />

          <SingleLineInput
            title={'Номер шаблона'}
            description={'Номер одного из ранее используемых шаблонов'}
            id={'google-id'}
            name={'googleId'}
            inputType={'text'}
            type={'number'}
            minLength={3}
            required={true}
            maxLength={9}
            setFn={setGoogleId}
            setIsValidFn={setGoogleIdIsValid}
            value={googleId}
          />
          <SingleLineInput
            title={'Номер телефона'}
            description={'Номер используемый для входа в личный кабинет'}
            id={'phone-number'}
            name={'phoneNumber'}
            inputType={'text'}
            type={'number'}
            minLength={11}
            required={true}
            maxLength={14}
            setFn={setPhoneNumber}
            setIsValidFn={setPhoneNumberIsValid}
            value={phoneNumber}
          />
          <SingleLineInput
            title={'E-mail'}
            description={'Потребуется для обратной связи по заявке'}
            id={'email'}
            required={true}
            name={'email'}
            type={'email'}
            inputType={'email'}
            minLength={6}
            maxLength={320}
            setFn={setEmail}
            setIsValidFn={setEmailIsValid}
            value={email}
          />

          <h2 className="withdrawal-funds__title">Паспортные данные</h2>
          <p className="withdrawal-funds__description withdrawal-funds__description_normal">
            Укажите данные действующего паспорта гражданина РФ
          </p>
          <SingleLineInput
            title={'Серия'}
            name={'passportSerial'}
            id={'passport-serial'}
            inputType={'text'}
            type={'number'}
            minLength={4}
            maxLength={4}
            required={false}
            setFn={setPassportSerial}
            setIsValidFn={setPassportSerialIsValid}
            value={passportSerial}
          />
          <SingleLineInput
            title={'Номер'}
            name={'passportNumber'}
            id={'passport-number'}
            inputType={'text'}
            type={'number'}
            minLength={6}
            maxLength={6}
            required={false}
            setFn={setPassportNumber}
            setIsValidFn={setPassportNumberIsValid}
            value={passportNumber}
          />
          <SingleLineInput
            title={'Дата выдачи'}
            description={'Дата выдачи паспорта'}
            name={'passportIssueDate'}
            id={'passport-number'}
            inputType={'date'}
            type={'date'}
            minLength={0}
            maxLength={16}
            required={false}
            setFn={setPassportIssueDate}
            setIsValidFn={setPassportIssueDateIsValid}
            value={passportIssueDate}
          />
          <UploadButton
            text={'Прикрепить фото с паспортом'}
            description={
              'Сфотографируйте или отсканируйте первый разворот паспорта с ФИО и фотографией. Максимальный размер файла — 10 Мб.'
            }
            setFn={setFile}
            setIsValidFn={setFileIsValid}
          />

          <h2 className="withdrawal-funds__title">
            Выберите направление вывода средств{' '}
          </h2>
          <ul className="withdrawal-funds__payment-list">
            {/*<li className="withdrawal-funds__payment-item">*/}
            {/*  <input*/}
            {/*    name="payment-type"*/}
            {/*    id="mobile-phone"*/}
            {/*    type="radio"*/}
            {/*    className="withdrawal-funds__payment-type"*/}
            {/*    value="mobile-phone"*/}
            {/*    onChange={(event) => setWithdrawType(event.target.value)}*/}
            {/*    checked={withdrawType === 'mobile-phone'}*/}
            {/*    required*/}
            {/*  />*/}
            {/*  <label*/}
            {/*    htmlFor="mobile-phone"*/}
            {/*    className="withdrawal-funds__payment-name"*/}
            {/*  >*/}
            {/*    Мобильный телефон*/}
            {/*  </label>*/}
            {/*</li>*/}
            <li className="withdrawal-funds__payment-item">
              <input
                name="payment-type"
                id="bank-card"
                onChange={(event) => setWithdrawType(event.target.value)}
                checked={withdrawType === 'bank-card'}
                type="radio"
                className="withdrawal-funds__payment-type"
                value="bank-card"
              />
              <label
                htmlFor="bank-card"
                className="withdrawal-funds__payment-name"
              >
                Банковская карта
              </label>
            </li>
            {/*<li className="withdrawal-funds__payment-item">*/}
            {/*  <input*/}
            {/*    name="payment-type"*/}
            {/*    id="bank-account"*/}
            {/*    value={'bank-account'}*/}
            {/*    checked={withdrawType === 'bank-account'}*/}
            {/*    onChange={(event) => setWithdrawType(event.target.value)}*/}
            {/*    type="radio"*/}
            {/*    className="withdrawal-funds__payment-type"*/}
            {/*  />*/}
            {/*  <label*/}
            {/*    htmlFor="bank-account"*/}
            {/*    className="withdrawal-funds__payment-name"*/}
            {/*  >*/}
            {/*    Банковский счёт*/}
            {/*  </label>*/}
            {/*</li>*/}
            <li className="withdrawal-funds__payment-item">
              <input
                name="payment-type"
                id="qiwi-wallet"
                value={'qiwi-wallet'}
                checked={withdrawType === 'qiwi-wallet'}
                onChange={(event) => setWithdrawType(event.target.value)}
                type="radio"
                className="withdrawal-funds__payment-type"
              />
              <label
                htmlFor="qiwi-wallet"
                className="withdrawal-funds__payment-name"
              >
                QIWI Кошелёк
              </label>
            </li>
          </ul>

          <div className="withdrawal-funds__payment-data">
            {selectWithdrawType()}
          </div>

          <div className="withdrawal-funds__agree-with-rules">
            <input
              id="agree-with-rules"
              type="checkbox"
              onChange={() => setIsAgree(!isAgree)}
              className="withdrawal-funds__checkbox"
            />
            <label
              htmlFor="agree-with-rules"
              className="withdrawal-funds__checkbox-description"
            >
              Согласен на обработку{' '}
              <a
                className="withdrawal-funds__link"
                href="https://static.qiwi.com/business/qiwi_bank/about/reports/policy_regarding_of_personal_data.pdf"
                target={'_blank'}
                rel="noreferrer"
              >
                персональных данных
              </a>
            </label>
          </div>
          <button
            disabled={!isAgree}
            type={'button'}
            className={`withdrawal-funds__complete ${
              isAgree && fieldsAreValid()
                ? 'withdrawal-funds__complete_enable'
                : 'withdrawal-funds__complete_disable'
            }`}
            onClick={handleSubmit}
          >
            Отправить заявку
          </button>
        </form>
      </div>
    </>
  );
};

interface WithdrawTypeFragment {
  fields: WithdrawTypeField[];
  attention: string;
}

interface WithdrawTypeField {
  title: string;
  description?: string;
  id: string;
  name: string;
  minLength: number;
  maxLength: number;
}
