import React from 'react';
import logo from '../../styles/wallet-logo.png';
import { Link } from '@reach/router';

export interface WithdrawHeaderLinksProps {}

export const WithdrawHeaderLinks: React.FC<WithdrawHeaderLinksProps> = () => {
  const linkClass = `no-underline hover:underline hover:text-blue text-gray-3 leading-8`;

  return (
    <div className={`flex`}>
      <div className={`flex-initial`}>
        <Link to="/" className={linkClass}>
          <img src={logo} width={35} height={35} alt="Online Rapida" />
        </Link>
      </div>

      {/*<div className={`flex-initial pl-8`}>*/}
      {/*  <Link to="withdraw/faq" className={linkClass}>*/}
      {/*    Вопросы и ответы*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </div>
  );
};
