import React from 'react';
import { Link } from '@reach/router';
import { WithdrawHeaderLinks } from '../molecules/WithdrawHeaderLinks';

export interface WithdrawHeaderProps {
  banner: string;
  link: string;
}

export const WithdrawHeader: React.FC<WithdrawHeaderProps> = ({
  banner,
  link,
}) => {
  return (
    <div className="grid gap-x-8 gap-y-4 grid-cols-header grid-rows-header">
      <div>
        <WithdrawHeaderLinks />
      </div>
      <div className="row-span-2"> </div>
      <div>
        <Link to={link}>
          <img src={banner} alt="" className="rounded-sm" />
        </Link>
      </div>
    </div>
  );
};
