import React, { ChangeEvent, useState } from 'react';

interface UploadButtonModel {
  text: string;
  description: string;
  setFn: (_: File | null) => void;
  setIsValidFn: (_: boolean) => void;
}

export const UploadButton: React.FC<UploadButtonModel> = ({
  text,
  description,
  setFn,
  setIsValidFn,
}) => {
  const [fileOrDefault, setFileOrDefault] = useState(text);
  const [currentDescription, setCurrentDescription] = useState(description);
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files !== null) {
      const maxFileSize = 1024 * 1024 * 10;
      const file = files[0];
      if (file.size <= maxFileSize && checkValidTypes(file.type)) {
        setCurrentDescription(description);
        setFileOrDefault(file.name);
        setFn(file);
        setIsValidFn(true);
      } else {
        setIsValidFn(false);
        setFileOrDefault(text);
        setCurrentDescription(
          'Ошибка при загрузке файла! Проверьте формат и размер файла. Требования к размеру файла - не более 10 мб. ' +
            'Требования к формату: jpeg, png, webp, tiff, pdf, doc, docx'
        );
      }
    } else {
      setIsValidFn(true);
      setFn(null);
    }
  };
  const checkValidTypes = (currentType: string) => {
    // Need your attention. Is it all valid types or need more?
    const validTypesList = [
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/tiff',
      'application/pdf',
      'application/msword', // doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
    ];
    const result = validTypesList.find((type) => type === currentType);
    return result !== undefined;
  };

  return (
    <>
      <div className="withdrawal-funds__attachment">
        <label
          htmlFor="upload-file"
          className={`withdrawal-funds__button ${
            fileOrDefault !== text
              ? 'withdrawal-funds__button_loaded'
              : 'withdrawal-funds__button_unloaded'
          }`}
        >
          <p id="upload-file-text">{fileOrDefault}</p>
        </label>
        <input
          id="upload-file"
          name="attachment"
          type="file"
          className="withdrawal-funds__file"
          onChange={handleUploadFile}
        />
      </div>
      <p
        className={`withdrawal-funds__description withdrawal-funds__description_small ${
          currentDescription !== description &&
          'withdrawal-funds__description_error'
        }`}
      >
        {currentDescription}
      </p>
    </>
  );
};
